// menu toggle
$('.menu-button').on('click', function (event) {
    event.preventDefault();

    // body functions prevent scrolling
    var menuContent = $('#menu-wrapper');
    var menuElementOpen = $('.menu-button.open');
    var menuElementClosed = $('.menu-button.close');
    var body = $('body');

    if (menuContent.hasClass('visible')) {
        menuContent.removeClass('visible');
        menuElementOpen.addClass('visible');
        menuElementClosed.removeClass('visible');

        body.css('overflow', '');
        body.css('position', '');
    } else {
        menuContent.addClass('visible');
        menuElementOpen.removeClass('visible');
        menuElementClosed.addClass('visible');

        body.css('overflow', 'hidden');
        body.css('position', 'fixed');
    }
});

$('.top-level').on('click', function (event) {
    var collapseLink = $(event.currentTarget);
    var groupIsAlreadyExpanded = collapseLink.parent().hasClass('active');
    var dataValue = collapseLink.data('action');
    if (dataValue === 'skipCollapse') {
        return;
    }

    // skip link follow
    event.preventDefault();

    var childrens = $('.menu-children, .menu-group, .top-level');
    collapseLink.removeClass('active');
    for (var i = 0; i < childrens.length; i++) {
        var childContainer = $(childrens[i]);
        childContainer.removeClass("active");
    }
    if (!groupIsAlreadyExpanded) {
        var childrenMenuSibling = collapseLink.siblings('.menu-children');
        var childrenParent = collapseLink.parent();
        collapseLink.addClass('active');

        childrenMenuSibling.addClass('active');
        childrenParent.addClass('active');
    }
});
